import { createStore } from 'vuex'
import { User } from '@/store/modules/User'
import { Join } from '@/store/modules/Join'

const store = createStore({
  modules: {
    User,
    Join
  }
})

export default store
