import md5 from 'js-md5'

export const Join = {
  namespaced: true,

  state: () => ({
    is_active: true,
    mobile: undefined,
    name: undefined,
    username: undefined,
    gender: undefined,
    password: undefined
    // roleIds: []
  }),

  mutations: {
    SET_ACTIVE: (state, value) => {
      state.is_active = value
    },
    SET_MOBILE: (state, value) => {
      state.mobile = value
    },
    SET_NAME: (state, value) => {
      state.name = value
    },
    SET_USERNAME: (state, value) => {
      state.username = value
    },
    SET_GENDER: (state, value) => {
      state.gender = value
    },
    SET_PASSWORD: (state, value) => {
      state.password = md5(value)
    },
    SET_ROLE_IDS: (state, value) => {
      state.roleIds = value
    }
  },

  actions: {
    fetchActive({ commit }, value) {
      commit('SET_ACTIVE', value)
    },

    fetchMobile({ commit }, value) {
      commit('SET_MOBILE', value)
    },

    fetchName({ commit }, value) {
      commit('SET_NAME', value)
    },

    fetchUsername({ commit }, value) {
      commit('SET_USERNAME', value)
    },
    
    fetchGender({ commit }, value) {
      commit('SET_GENDER', value)
    },

    fetchPassword({ commit }, value) {
      commit('SET_PASSWORD', value)
    },

    fetchRoleIds({ commit }, value) {
      commit('SET_ROLE_IDS', value)
    }
  },

  getters: {
    getJoinInfo(state) {
      return state
    }
  }
}
