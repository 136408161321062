import grade from './grade'
import price from './price'
import number from './number'
import capacity from './capacity'

const filters = {
  ...grade,
  ...price,
  ...number,
  ...capacity
}
export default filters
