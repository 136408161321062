import router from './router'
import { getToken, getTwitterKey } from '@/utils/auth'
import { useStore } from 'vuex'
import md5 from 'js-md5'

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
  const twitterKey = getTwitterKey()
  const store = useStore()
  
  if (twitterKey && !getToken()) {
    const param = {
      username: twitterKey,
      password: md5(`${twitterKey}vp`)
    }
    const response = await store.dispatch('User/fetchLogin', param)
    if (response && response.data.token) { 
      if (to.path === '/login') {
        next({ path: '/login' })
      } 
    } 
  }

  if (getToken() && getToken() !== 'undefined') {
    /* has token */
    if (to.path === '/login') {
      next({ path: '/main' })
    } else {
      /* if (store && store.getters.roles.length === 0) {
        // 현재 사용자가 user_info 정보를 가져왔는지 판단합니다
        store.dispatch('GetInfo').then(res => {
          // user_info 가져오기
          const roles = res.roles
          store.dispatch('GenerateRoutes', { roles }).then(accessRoutes => {
            // roles 권한에 따라 접근 가능한 공유표를 생성합니다
            router.addRoutes(accessRoutes) // 액세스할 수 있는 공유표 동적으로 추가
            next({ ...to, replace: true }) // hack 메소드 addRoutes 완료됨
          })
        }).catch(() => {
          store.dispatch('User/fetchLogout').then(() => {
            // Message.error(err);
            next({ path: '/' })
          })
        })
      } else {
        next()
      } */

      if (to.path === '' || to.path === '/') {
        next({ path: '/main' })
      }
      next()
    }
  } else {
    // token 없음
    /* if (whiteList.indexOf(to.path) !== -1) {
      // 리스트에 로그인하지 않고 바로 들어가다
      next()
    } else {
      // 로그인 페이지로 이동한다.
      next({ path: '/login' }) 
    } */

    // next()
    if (to.path === '' || to.path === '/') {
      next({ path: '/main' })
    } else if (to.path.includes('/my') || to.path.includes('/board-write') || to.path.includes('/reply-write')) {
      next({ path: '/login' })
    }
    next()
  }
})

/* router.afterEach(() => {
  // NProgress.done();
}) */
