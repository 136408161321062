const capacity = {
  capacityTxt: (value) => {
    const size = Number(value)
    let result = `${value} GB`
    if (size > 1024) {
      result = `${size / 1024} TB`
    }
    return result
  }
}

export default capacity
