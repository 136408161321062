const price = {
  currencyWON: (value) => {
    if (value) {
      return `${value.toLocaleString('ko-KR')}원`
    } else {
      return `${0}원`
    }
  }
}

export default price
