import Cookies from 'js-cookie'

const TokenKey = 'user-token'
const TwitterKey = 'tw'

export default TokenKey

export const getTwitterKey = () => {
  return Cookies.get(TwitterKey)
}

export const getToken = () => {
  return Cookies.get(TokenKey)
}

export const setToken = (token) => {
  return Cookies.set(TokenKey, token)
}

export const removeToken = () => {
  return Cookies.remove(TokenKey)
}
