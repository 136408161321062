import request from '@/utils/request'
const BACK_API = '/back/api'
const BASE_PATH = `${BACK_API}/vote`

const getpointSave = (query) => {
  return request({
    url: `${BASE_PATH}/vote/point/point_save/`,
    method: 'get',
    params: query
  })
}

export { 
    getpointSave
}
