import { postLogin, getInfo, postLogout } from '@/api/login/login'
import { getToken, setToken, removeToken } from '@/utils/auth'

export const User = {
  namespaced: true,

  state: () => ({
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    unread_msg_count: 0
  }),

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    },
    SET_UNREAD_MSG_COUNT: (state, unread_msg_count) => {
      state.unread_msg_count = unread_msg_count
    }
  },

  actions: {
    // 로그인
    fetchLogin({ commit }, userInfo) {
      // const username = userInfo.username.trim();
      // const password = userInfo.password;
      // const code = userInfo.code;
      // const uuid = userInfo.uuid;
      return new Promise((resolve, reject) => {
        postLogin(userInfo)
          .then((res) => {
            setToken(res.data.token)
            commit('SET_TOKEN', res.data.token)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 사용자 정보 조회
    GetInfo({ commit, state }) {
			return new Promise((resolve, reject) => {
				getInfo(state.token)
					.then((res) => {
						const user = res.data.user
						if (res.data.roles && res.data.roles.length > 0) {
							// 반환된 roles가 비어있지 않은 배열임을 확인합니다.
							commit('SET_ROLES', res.data.roles)
							commit('SET_PERMISSIONS', res.data.permissions)
						} else {
							commit('SET_ROLES', ['ROLE_DEFAULT'])
						}
						commit('SET_NAME', user.name)
						commit('SET_UNREAD_MSG_COUNT', user.unread_msg_count)
						resolve(res)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},

    // 로그아웃
    fetchLogout({ commit, state }) {
      return new Promise((resolve, reject) => {
        postLogout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            removeToken()
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
  }
}
