import axios from 'axios'
import { toastController } from '@ionic/vue'
import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: '', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000, // request timeout
  headers: {
    'Access-Control-Allow-Origin': '*'
  },
  withCredentials: true
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent

    // token 설정 필요 여부
		const isToken = (config.headers || {}).isToken === false
		if (getToken() && !isToken) {
			config.headers['Authorization'] = 'Bearer ' + getToken() // 각 요청마다 사용자 정의 토큰을 가져오도록 하기 위해 상황에 맞게 수정해 주시기 바랍니다
		}
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  async (response) => {
    // if the custom code is not 20000, it is judged as an error.

    const scode = [200, 201, 202, 203, 204, 205, 206, 207, 208, 226]
    if (scode.includes(response.status)) {
      return response.data
    } else {
      const toast = await toastController.create({
        header: '오류',
        message: response.data.msg,
        position: 'bottom',
        duration: 2000
      })
      await toast.present()
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
